<!-- BEGIN BREADCRUMBS -->
<div class="breadcrumbs">    
    <div class="row">
        <div  class="col-md-4"><h1>Embed Project</h1></div>
        <div  class="col-md-4">
            <div class="form-group" *ngIf="curpageurl!='/dash/annexurec'">
                <label style="color: #e73d4a;">Slum/Village Type</label>            
                <ng-multiselect-dropdown [settings]="ddlMultiSlumTypeSettings"
                                        [data]="lstAdministrativeType"                                     
                                        [(ngModel)]="selectedAdministrativeType"
                                        [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
            </div>								
        </div>
        <div  class="col-md-2">
            <div class="form-group">
                <label style="color: #e73d4a;">Date</label>
                <input type="text" matInput
                    ngxDaterangepickerMd
                    [locale]="{applyLabel: 'ok', format: 'DD/MM/YYYY'}"
                    [drops]="'down'"
                    [opens]="'left'"
                    [ranges]="ranges"
                    [showCustomRangeLabel]="customrangeshow"
                    [alwaysShowCalendars]="shocalendaralways"
                    [keepCalendarOpeningWithRange]="false"
                    [showCancel]="true"
                    [showClearButton]="false"
                    autocomplete="off"
                    [showRangeLabelOnInput]="false"
                    placeholder="Select Dates"
                    [isInvalidDate] = "isInvalidDate"                    
                    [(ngModel)]="selectedDateRange"
                    (ngModelChange)="updateFilterValue('selectedDateRange', selectedDateRange,false)"
                    name="daterange"/>
                <!-- [isTooltipDate]="isTooltipDate" -->
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <button style="margin-top: 15px;" type="submit" class="btn btn-primary" (click)="onLoad()">Load Data</button>
            </div>
        </div>
    </div>            
</div>
<!-- END BREADCRUMBS -->				
<!-- BEGIN PAGE CONTENT INNER -->
<div class="panel panel-danger">
    <div class="panel-heading" id="myHeader">					
        <div class="row" ng-init="setLoginData();">
            <div class="col-md-2">
                <div class="form-group">
                    <label>State</label>								
                    <select class='form-control' [(ngModel)]="SelStateID" (change)="getDistricts();updateFilterValue('SelStateID', SelStateID,false)" [disabled]="CheckLoginType('State')">
                        <option value="0">All State </option>
                        <option value="{{row.State_id}}" *ngFor="let row of lstState">{{row.State_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>District</label>								
                    <select class='form-control' [(ngModel)]="SelDistrictID" (change)="getMunicipalArea();updateFilterValue('SelDistrictID', SelDistrictID,false)" [disabled]="CheckLoginType('District')">
                    <option value="0">All District </option>
                    <option value="{{row.District_id}}" *ngFor="let row of lstDistrict">{{row.District_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2"  ng-hide="true">
                <div class="form-group">
                    <label>Nagar Nigam / Block</label>
                    <select class='form-control' [(ngModel)]="SelMunicipalAreaID" (change)="getBCCFZone();updateFilterValue('SelMunicipalAreaID', SelMunicipalAreaID,false)" [disabled]="CheckLoginType('MunicipalArea')">
                        <option value="0">All Nagar Nigam / Block</option>
                        <option value="{{row.MunicipalArea_id}}" *ngFor="let row of lstMunicipalArea">{{row.MunicipalArea_Name}}</option>
                    </select>									
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>BCCF Zone</label>
                    <select class='form-control' [(ngModel)]="SelBCCFZoneID" (change)="getTerritorialArea();updateFilterValue('SelBCCFZoneID', SelBCCFZoneID,false)" [disabled]="CheckLoginType('BCCFZone')">
                        <option value="0">All BCCF Zone</option>
                        <option value="{{row.BCCFZone_id}}" *ngFor="let row of lstBCCFZone">{{row.BCCFZone_Name}}</option>
                    </select>									
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group" *ngIf="curpageurl!='/dash/calendar' && curpageurl!='/dash/annexurec'">
                    <label tooltip="Ward/Panchayat">Ward / Panchayat</label>
                    <select class='form-control'[(ngModel)]="SelTerritorialAreaID" (change)="getAdministratorArea();updateFilterValue('SelTerritorialAreaID', SelTerritorialAreaID,false)">
                        <option value="0">All Ward / Panchayat</option>
                        <option value="{{row.TerritorialArea_id}}" *ngFor="let row of lstTerritorialArea">{{row.TerritorialArea_Name}}</option>
                    </select>									
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group" *ngIf="curpageurl!='/dash/calendar' && curpageurl!='/dash/annexurec'">
                    <label tooltip="Slum/Village">Slum / Village</label>
                    <select class='form-control' [(ngModel)]="SelAdministratorAreaID" (ngModelChange)="updateFilterValue('SelAdministratorAreaID', SelAdministratorAreaID,false)">		
                        <option value="0">All Slum / Village</option>
                        <option value="{{row.Administrative_Area_id}}" *ngFor="let row of lstAdministratorArea">{{row.Administrative_Area_Name}}</option>
                    </select>									
                </div>
            </div>									
        </div>
        
    </div>
</div>
<!-- END PAGE CONTENT INNER -->
<main class="bg-grey px-3"> 
    <p-tabView  (onChange)="handleChange($event.index)" [(activeIndex)]="activeindex">
        <p-tabPanel header="Dynamic Data" [disabled]="false" >                              
            <div class="row mt-3 g-1">
                <div class="col-lg-9">
                    <div class="row g-1">
                        <div class="col-sm-6 col-md-4" *ngFor="let item of DynamicSummaryData | sort:criteria">
                            <app-achieve-target [isValueSelect]="item.isValueSelect" [valuefor]="item.valuefor" [datafor]="item.datafor" [title]="item.title" [totalachieved]="item.totalachieved" [target]="item.target" [achieved]="item.achieved" [isdataloaded]="item.isdataloaded"></app-achieve-target>
                        </div>
                    </div>
                </div>  
                <div class="col-lg-3">
                    <div class="card shadow-sm">
                        <div class="card-body" style="min-height: 135px;"> 
                            <div class="row" *ngIf="!DynamicSummaryData[2]?.isdataloaded">
                                <div class="col-12">
                                    <p-progressSpinner [style]="{ width: '80px', height: '80px' }" aria-label="Loading" ></p-progressSpinner>
                                </div>
                            </div>
                            <div class="row" *ngIf="DynamicSummaryData[2]?.isdataloaded">
                                <div class="col-4" *ngIf="SummaryData.HHLarvaFound != 'undefined' && SummaryData.HHSurveyed != '0'">
                                    <div class="row">
                                        <div class="col-12" style="text-align: center;">
                                            <span class="event-title">HI</span>
                                        </div>
                                        <div class="col-12"> 
                                            <p-knob [size]="70" [(ngModel)]="HI" valueTemplate="{{HI}}%" />               
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="SummaryData.LarvaContainerFound != 'undefined' && SummaryData.HHSurveyed != '0'">
                                    <div class="row">
                                        <div class="col-12" style="text-align: center;">
                                            <span class="event-title">BI</span>
                                        </div>
                                        <div class="col-12">                
                                            <p-knob [size]="70" [(ngModel)]="BI" valueTemplate="{{BI}}%" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="SummaryData.LarvaContainerFound != 'undefined' && SummaryData.ContainerFound != '0'">
                                    <div class="row">
                                        <div class="col-12" style="text-align: center;">
                                            <span class="event-title">CI</span>
                                        </div>
                                        <div class="col-12">                
                                            <p-knob [size]="70" [(ngModel)]="CI" valueTemplate="{{CI}}%" />
                                        </div>
                                    </div>
                                </div>       
                            </div>
                        </div>
                    </div>
                    <app-upcoming-events [lstActivityType]="ActivitySummaryData" [isdataloaded]="isactivitydataloaded"></app-upcoming-events>
                </div>     
            </div> 
            
        </p-tabPanel>   
        <p-tabPanel header="Graph">
            <div class="row">
                
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><p-progressSpinner [style]="{ width: '150px', height: '150px' }" aria-label="Loading" *ngIf="!isgraph1loaded"></p-progressSpinner><div id="chartmethodused" style="width: 100%; height: 500px"></div></div>
                <div class="col-md-12"><p-progressSpinner [style]="{ width: '150px', height: '150px' }" aria-label="Loading" *ngIf="!isgraph2loaded"></p-progressSpinner><div id="chartcontainer" style="width: 100%; height: 500px"></div></div>
            </div>              
        </p-tabPanel>     
        <p-tabPanel header="Static Data">
            <div class="row mt-3 g-1">
                        <div class="col-sm-6 col-md-2">                
                            <app-stats [datafor]="'State'" [title]="'State'" [value]="StaticSummaryData.State" [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'District'" [title]="'District'" [value]="StaticSummaryData.District"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'MunicipalArea'" [title]="'NN / Block'" [value]="StaticSummaryData.MunicipalArea"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                          <app-stats [datafor]="'BCCFZone'" [title]="'BCCFZone'" [value]="StaticSummaryData.BCCFZone"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                    
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'TerritorialArea'" [title]="'Ward / Panchayat'" [value]="StaticSummaryData.TerritorialArea"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'AdministrativeArea'" [valuefor]="'INTENSIVE'" [title]="'Basti / Village (INTENSIVE)'" [value]="StaticSummaryData.Administrative_Area_Intensive"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div> 
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'AdministrativeArea'" [valuefor]="'CIVIC'" [title]="'Basti / Village (CIVIC)'" [value]="StaticSummaryData.Administrative_Area_Civic"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>  
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'AdministrativeArea'" [valuefor]="'SCALEUP'" [title]="'Basti / Village (SCALEUP)'" [value]="StaticSummaryData.Administrative_Area_Scaleup"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'AdministrativeArea'" [valuefor]="'PHASE OUT'" [title]="'Basti / Village (PHASE OUT)'" [value]="StaticSummaryData.Administrative_Area_Phaseout"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>                  
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'Cluster'" [title]="'Clusters'" [value]="StaticSummaryData.Cluster"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'User'" [title]="'Users'" [value]="StaticSummaryData.User"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'HouseHold'" [title]="'HouseHolds'" [value]="StaticSummaryData.HouseHold"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'Population'" [title]="'Population'" [value]="StaticSummaryData.Population"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'PregnantWomen'" [title]="'Pregnant Women'" [value]="StaticSummaryData.PregnantCount"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'Children'" [title]="'Children'" [value]="StaticSummaryData.ChildCount"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                        </div>                                    
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'WaterStagnation'" [title]="'Water Stagnations'" [value]="StaticSummaryData.WaterSatgnation"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'OutletData'" [title]="'Outlet Data'" [value]="StaticSummaryData.OutletData"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'SchoolData'" [title]="'School Data'" [value]="StaticSummaryData.SchoolData"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ProjectStaff'" [activevalue]="StaticSummaryData.ActiveProjectStaff" [title]="'Project Staff'" [value]="StaticSummaryData.ProjectStaff"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                    
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ServiceProvider'" [valuefor]="'0'" [title]="'Other Service Provider'" [value]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '0','')"  [isdataloaded]="isservproviderdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ServiceProvider'" [valuefor]="'1'" [title]="'Asha / Mitanin'" [activevalue]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '1','ACTIVE')" [value]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '1','')"  [isdataloaded]="isservproviderdataloaded" [change]="-1"></app-stats>
                        </div>                    
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ServiceProvider'" [valuefor]="'7'" [title]="'Ojha'" [activevalue]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '7','ACTIVE')" [value]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '7','')"  [isdataloaded]="isservproviderdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ServiceProvider'" [valuefor]="'8'" [title]="'HCP / RHCP'" [activevalue]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '8','ACTIVE')" [value]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '8','')"  [isdataloaded]="isservproviderdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ServiceProvider'" [valuefor]="'9'" [title]="'CV'" [activevalue]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '9','ACTIVE')" [value]="getFirstMatchingObject(ServiceProviderSummaryData,'ProviderType_id', '9','')"  [isdataloaded]="isservproviderdataloaded" [change]="-1"></app-stats>
                        </div>                
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ActivityType'" [title]="'Activity Type'" [value]="StaticSummaryData.ActivityType"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'ProjectStaffType'" [title]="'Staff Type'" [value]="StaticSummaryData.StaffType"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>   
                        <div class="col-sm-6 col-md-2">
                            <app-stats [datafor]="'HNDSite'" [title]="'HND Sites'" [value]="StaticSummaryData.HNDSites"  [isdataloaded]="isstaticdataloaded" [change]="-1"></app-stats>
                        </div>           
            </div>
        </p-tabPanel>
        <p-tabPanel header="Planning Data">
            <div class="row mt-3 g-1">                            
                <div class="col-sm-12 col-md-12" style="text-align: right;">
                    <a class="btn-viewmore" (click)="ViewPlanningSummary()" href="javascript:;">View Planning Summary</a>
                    <p-progressSpinner [style]="{ width: '30px', height: '30px' }" aria-label="Loading" *ngIf="!ispopupdataloaded"></p-progressSpinner>
                    <p-dialog header="Planning Summary" [modal]="true" [(visible)]="popupvisible" [style]="{ width: '80%' }">
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="printDiv()" class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                        &nbsp;<button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportTableToExcel('PdfDiv')" class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                        <div class="row">
                            <div class="col-md-12" style="width:210mm !important;font-family: serif !important;" #PdfDiv id="PdfDiv">
                                <table class="table table-bordered">
                                    <thead class="flip-content">
                                        <tr>
                                            <th style="vertical-align: top;text-align: left;width:100px;" (click)="sortData('start')"> Date </th>
                                            <th style="vertical-align: top;text-align: left;width:150px;" (click)="sortData('district')"> District </th>
                                            <th style="vertical-align: top;text-align: left;width:150px;" (click)="sortData('muncipalarea')"> NN / Block </th>
                                            <th style="vertical-align: top;text-align: left;width:150px;" (click)="sortData('zone')"> BCCF Zone </th>
                                            <th style="vertical-align: top;text-align: left;width:150px;" (click)="sortData('territorial')"> Ward / Panchayat </th>
                                            <th style="vertical-align: top;text-align: left;width:150px;" (click)="sortData('administrative')"> Basti / Village  </th>
                                            <th style="vertical-align: top;text-align: left;width:150px;" (click)="sortData('eventtype')"> Activity </th>
                                            <th style="vertical-align: top;text-align: left;width:150px;"> Remark </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let staff of PlanningDataSummaryPopup  | sort: criteriaplanning let i = index" style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                            <td  style="text-align: left;"> {{staff.displaydate	 }} </td>
                                            <td> {{staff.district}} </td>
                                            <td> {{staff.muncipalarea	}} </td>
                                            <td> {{staff.zone	}} </td>
                                            <td  style="text-align: left;"> {{staff.territorial	}} </td>
                                            <td  style="text-align: left;"> {{staff.administrative	 }} </td>
                                            <td  style="text-align: left;"> {{staff.eventtype	 }} </td>
                                            <td  style="text-align: right;"> {{staff.title	}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </p-dialog>
                </div>
            </div>
            <div class="row mt-3 g-1">                            
                <div class="col-sm-6 col-md-3">                
                    <app-stats [datafor]="'ChaupalPlanning'" [valuefor]="'Chaupal'" [title]="'Chaupal Planning'" [value]="PlanningSummaryData.ChaupalPlanning" [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'HNDPlanning'" [title]="'Health and Nutrition Day Planning'" [value]="PlanningSummaryData.HNDPlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>                
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'EModulePlanning'" [valuefor]="'IV'" [valueforid]="'9'" [title]="'CV Training Planning'" [value]="PlanningSummaryData.CVTrainingPlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>                      
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'EModulePlanning'" [valuefor]="'IV'" [valueforid]="'1'" [title]="'E-module ASHA training planner'" [value]="PlanningSummaryData.ServiceProviderEModulePlanningAsha"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'EModulePlanning'" [valuefor]="'IV'" [valueforid]="'8'" [title]="'E-module HCP training planner'" [value]="PlanningSummaryData.ServiceProviderEModulePlanningHCP"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'EModulePlanning'" [valuefor]="'NIV'" [title]="'E-module Rollout Planning'" [value]="PlanningSummaryData.EModulePlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'SchoolEventPlanning'" [title]="'School Event Planning'" [value]="PlanningSummaryData.SchoolEventPlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'ChaupalPlanning'"  [valuefor]="'CommunicationActivity'" [title]="'Communication Activity Planning'" [value]="PlanningSummaryData.CommunicationActivityPlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'ActivityPlanning'" [title]="'Activity Planning'" [value]="PlanningSummaryData.ActivityPlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'SSVisitPlanning'" [ismailavail]="true" [title]="'SS Visit Planning'" [value]="PlanningSummaryData.SSVisitPlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'MeetingPlanning'" [valuefor]="'ProjectUpdate'" [title]="'Project Update Planning'" [value]="PlanningSummaryData.ProjectUpdatePlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'MeetingPlanning'" [valuefor]="'WorkshopMeeting'" [title]="'Workshop and Coordination Meetings Planning'" [value]="PlanningSummaryData.WorkshopMeetingPlanning"  [isdataloaded]="isplanningdataloaded" [change]="-1"></app-stats>
                </div>
            </div>
            <div class="row mt-3 g-1" style="display: none;">
                <div class="col-md-12 recent-orders">
                    <app-recent-orders></app-recent-orders>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Survey Data">
            <div class="row mt-3 g-1">            
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'HouseholdSurvey'" [title]="'Household Checklist'" [value]="SurveySummaryData.HouseholdSurvey"  [isdataloaded]="issurveydataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">                
                    <app-stats [datafor]="'ProjectProviderSurvey'" [title]="'Asha / ANM Checklist'" [value]="SurveySummaryData.ProjectProviderSurvey" [isdataloaded]="issurveydataloaded" [change]="-1"></app-stats>
                </div>
                <div class="col-sm-6 col-md-3">
                    <app-stats [datafor]="'FLWSurvey'" [title]="'HCP Checklist'" [value]="SurveySummaryData.FLWSurvey"  [isdataloaded]="issurveydataloaded" [change]="-1"></app-stats>
                </div>                
                <div class="col-sm-6 col-md-3">                    
                </div>                      
            </div>
            
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="charthhsampledistrict" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="charthhtotsurvey" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <h5>Profile of the Respondents</h5>    
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartRespondentGrowth" style="width: 100%; height: 500px"></div></div>
            </div> 
            <div class="row mt-3 g-1">
                <h5>Knowledge and Awareness</h5>    
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="charteverheard" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">    
                <div class="col-md-12"><div id="chartdenguesympton" style="width: 100%; height: 500px"></div></div>
            </div> 
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartmalariasympton" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">    
                <div class="col-md-12"><div id="chartdenguecause" style="width: 100%; height: 500px"></div></div>
            </div> 
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartmalariacause" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartdengueprevented" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartmalariaprevented" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <h5>Practices</h5>    
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartfamilypractanymethod" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartmethodofprotect" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <h5>Health seeking behaviour</h5>    
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartfamilyhighfewer" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartvisittohcp" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="charttypeofhcp" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartbloodtestconduct" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="charttreatmentopted" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartheardseenmessage" style="width: 100%; height: 500px"></div></div>
            </div>     
            <div class="row mt-3 g-1">
                <h5>Providers and Health workers</h5>    
            </div>
            
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="charthcsampledistrict" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="charthctotsurvey" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart1" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart2" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart3" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart4" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart5" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart6" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart7" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart8" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart9" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart10" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart11" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <h5>Provider</h5>    
            </div>            
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartprovsampledistrict" style="width: 100%; height: 500px"></div></div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chartprovtotsurvey" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart12" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart13" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart14" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart15" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart16" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart17" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart18" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart19" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart20" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart21" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart22" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart23" style="width: 100%; height: 500px"></div></div>
            </div> 3
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart24" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart25" style="width: 100%; height: 500px"></div></div>
            </div>  
            <div class="row mt-3 g-1">
                <div class="col-md-12"><div id="chart26" style="width: 100%; height: 500px"></div></div>
            </div>  
        </p-tabPanel>
        <!-- <p-tabPanel header="Field Transect">
            <app-attendance></app-attendance>
        </p-tabPanel> -->
    </p-tabView>    
</main>